<template>
    <div id="container" class="md-layout">
        <h1 class="md-layout-item md-size-80 md-small-size-90">Allgemeine Nutzungsbedingungen</h1>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    1. Geltungsbereich
                </div>
            </md-card-header>
            <md-card-content>
                PAW ist eine Plattform für Orthopädieschuhmacher, Orthopädietechniker und Sanitätshäuser, die die Zulassung für
                die Produktgruppe 08 haben (Partner) und Endkunden (Nutzer).
                <br>
                PAW stellt die kontaktfreie Verbindung zwischen Nutzer und Partner her und ermöglicht, einen digitalen Fußabdruck
                zu gewinnen, ihn weiterzuleiten, zu bearbeiten und für Einlagenherstellung und Schuhgrößenfindung herunterzuladen.
                Eine projektgebundene Kommunikation zwischen Nutzer und Partner wird ermöglicht.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    2. Kosten
                </div>
            </md-card-header>
            <md-card-content>
                Die Nutzung der Plattform ist für Partner und Nutzer kostenfrei. Erst bei Download des bearbeiteten und für gut
                befundenen Abdrucks wird eine vertraglich geregelte Gebühr von momentan 10,- € zzgl. MWST fällig.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    3. Download
                </div>
            </md-card-header>
            <md-card-content>
                Der Download gehört dem PAW-Partner. Er hat dabei aber die Rechte des Nutzers und die Datenschutzrichtlinien zu
                beachten.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    4. Abrechnung
                </div>
            </md-card-header>
            <md-card-content>
                Die Abrechnung erfolgt monatlich per Lastschrift oder bei Download, je nach Vertragsabschluß zwischen PAW und
                PAW-Partner.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    5. Haftungsausschluss
                </div>
            </md-card-header>
            <md-card-content>
                PAW übernimmt keine Haftung für die Produkte des Partners. Welche Produkte er wählt, ist Gegenstand der
                Geschäftsbeziehung zwischen PAW-Nutzer und PAW-Partner, PAW selbst stellt lediglich die Verbindung her. Auch die
                Qualität des Abdrucks liegt beim Partner, der erst den geprüften und selbst bearbeiteten Abdrucks herunterlädt.
                Für fehlerhafte Bearbeitung haftet der PAW-Partner. PAW haftet nicht für technische Probleme, die mit dem
                Internetzugang, der Hardware/Software des Nutzers/Partners oder fehlerhafter Eingaben zusammenhängen.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    6. Qualifikation
                </div>
            </md-card-header>
            <md-card-content>
                Der PAW-Partner verpflichtet sich, die Angaben zu seiner Qualifikation ordnungsgemäß anzugeben. Er muß sich mit
                Meisterbrief und Präqualifizierung anmelden und kann dann erst freigeschalten werden.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    7. Korrektheit der Angaben
                </div>
            </md-card-header>
            <md-card-content>
                Der PAW-Nutzer verpflichtet sich ebenso, seine Angaben korrekt anzugeben. Mit dem Erteilen eines Auftrags gibt er
                die Rechte am Bild an den Orthopädieschuhmacher seiner Wahl ab.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    8. Verhaltenskodex
                </div>
            </md-card-header>
            <md-card-content>
                Der PAW-Partner unterstützen PAW und tragen zum guten Gelingen der Plattform bei.
            </md-card-content>
        </md-card>
        <md-card class="md-layout-item md-size-80 md-small-size-90">
            <md-card-header>
                <div class="md-title">
                    9. Streitigkeiten
                </div>
            </md-card-header>
            <md-card-content>
                PAW behält sich vor, PAW-Partner gegebenenfalls zu sperren oder zu löschen. Falls Meinungsverschiedenheiten nicht
                im Gespräch und/oder mit Mediation zu beheben ist, ist der Gerichtsstand Ansbach.
            </md-card-content>
        </md-card>
    </div>
</template>

<script>
export default {
    name: 'All_PrivacyPolicy',
    props: {
        userdata: null
    },
    data() {
        return {};
    },
    components: {}
}
</script>

<style lang="scss" scoped>
#container {
    background-color: #FFFFFF;
    border: 1px solid #CCCCCC;
    padding: 20px;
    margin-top: 10px;
    align-content: center;
    text-align: center;
}

.md-card {
    text-align: left;
    width: 980px;
    max-width: 90%;
    margin: 10px auto 10px;
    display: inline-block;
    vertical-align: top;
}

h1 {
    text-align: center;
    margin: 10px auto 10px;
}
</style>
